.ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
  margin-bottom: 0;
  margin-left: 24px;
}

.fl-1 {
  flex: 1;
}

.mt-6 {
  margin-top: 6px;
}

.mr-20 {
  margin-right: 20px;
}

.mr-24 {
  margin-right: 24px;
}
.mr-12 {
  margin-right: 12px;
}

.ml-20 {
  margin-left: 20px;
}

.ml-24 {
  margin-left: 24px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-12 {
  margin-top: 12px !important;
}

.mb-24 {
  margin-bottom: 24px;
}

.mr-26 {
  margin-right: 26px;
}

.ml-12 {
  margin-left: 12px;
}

.minWidth-120 {
  min-width: 120px;
}

.pl-36 {
  padding-left: 36px !important;
}

.pr-10 {
  padding-right: 10px;
}
