@use 'src/styles/color';
@use 'src/styles/font';

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
}

a {
  color: inherit;
  text-decoration: inherit; /* no underline */
}

.ant-modal-body {
  padding-top: 16px !important;
}

.ant-modal-content {
  border-radius: 10px;
}

.ant-modal-header {
  border-radius: 10px 10px 0px 0px;
}

.ant-notification-notice {
  border-radius: 10px !important;
}

.ant-table-thead .ant-table-cell {
  background-color: transparent;
  font-weight: bold;
  font-size: font.$font-normal;
}

.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan]) {
  &:before {
    content: '';
    background: transparent;
  }
}

.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset; //Change the existing color to `unset`
}
