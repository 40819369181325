@use 'src/styles/font';
@use 'src/styles/color';
@import 'src/styles/flex';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px color.$white inset;
  transition: background-color 5000s ease-in-out 0s;
}

.ant-form-item-control {
  width: 100%;
}

.ant-select-single .ant-select-selector .ant-select-selection-item {
  height: 38px;
  line-height: 38px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 38px;
  line-height: 38px;
  // border-radius: 10px !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  line-height: 38px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-input {
  border-radius: 10px;
  border: 1px solid #e0e0e0;
  outline: 0;
}

.width {
  width: 100%;
}

.require {
  color: red;
}

.border {
  border-radius: 5px;
}

.form {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.input-item {
  height: 38px;
  font-size: 14px;
}

.form-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 11px !important;

  &__title {
    font: normal normal bold 20px/30px;
    font-weight: bold;
    color: #1b1e21;
  }

  &__label {
    font-style: normal;
    font-weight: 500;
    font-size: font.$font-normal;
  }
}

.ant-form-item-has-error {
  margin-bottom: -1px !important;
}

.ant-form-item-row {
  flex-direction: column;
  width: 100%;

  .ant-form-item-label {
    text-align: left;
  }
}

.ant-select-arrow {
  color: color.$primary !important;
}
