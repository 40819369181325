.loading3QuartersOutlinedStyled {
  font-size: 80px !important;
}

.spinStyled {
  position: absolute !important;
  top: 47%;
  left: 47%;
  transform: translateX(-47%, -47%);
  z-index: 9999;
}

.spinDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
}
