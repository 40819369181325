@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/Quicksand-SemiBold.ttf');
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/Quicksand-Bold.ttf');
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: 'Quicksand';
  src: url('../../public/fonts/Quicksand-Medium.ttf');
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}
body {
  font-family: 'Quicksand';
  font-size: normal normal medium 18px/24px;
}

$font-large: 32px;
$font-header: 30px;
$font-normal: 14px;
$font-small: 12px;
$font-medium: 16px;

.fz-20 {
  font-size: 20px;
}

.fw-7 {
  font-weight: 700;
}

.fw-6 {
  font-weight: 600;
}

.fw-5 {
  font-weight: 500;
}

.fw-4 {
  font-weight: 400;
}

.br-10 {
  border-radius: 10px;
}
