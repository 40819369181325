@use 'src/styles/font';
@use 'src/styles/color';
@import 'src/styles/flex';

.page-container {
  margin: 24px 43px 21px 24px;

  .d-header {
    display: flex;
    flex-direction: row;
    .t-title {
      color: color.$dark;
      font-size: font.$font-large;
      flex: 1;
    }
  }
}

.pointer {
  cursor: pointer;
}

.d-table {
  margin-top: 11px;
  .t-even {
    background-color: transparent;
  }
  .t-odd {
    background-color: #f1f3f5;
  }

  .table-stt {
    padding: 0px 5px;
  }

  .table-footer {
    @include flex_center;
    justify-content: space-between;
    margin-top: 20px;
    font-size: font.$font-normal;
    color: color.$secondary;
  }

  .table-stt {
    padding: 0px 5px;
  }
}
