@import './color.scss';

.ant-select-dropdown {
  border-radius: 10px;
  border-width: 2px;
  border-color: #9cccf7;
  border-style: solid;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgb(26 26 26);
  font-weight: normal;
  background-color: $white;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
  color: $primary;
  background-color: $light-disable;
  padding-left: 20px;
  transition: 0.15s all ease;
  font-weight: 500;
  &:hover {
    font-weight: 700;
  }
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector::after,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
.ant-select-single.ant-select-lg:not(.ant-select-customize-input)
  .ant-select-selector
  .ant-select-selection-placeholder {
  font-size: 14px;
}

.ant-select-multiple .ant-select-selection-item-content {
  font-size: 14px;
}

.ant-select-multiple .ant-select-selection-placeholder {
  font-size: 14px;
}
