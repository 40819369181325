@use 'src/styles/color';

$height-header: 60px;

.nav-header-blank {
  height: $height-header;
}

.nav-header {
  background-color: color.$spa-color;
  height: $height-header;
  flex-direction: row;
  flex: 1;
  display: flex;
  padding-left: 24px;
  padding-right: 52px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 100;
  .avatar-header {
    border-radius: 36px;
    width: 36px;
    height: 36px;
    margin: auto 0;
    margin-left: 32px;
    object-fit: cover;
    &:hover,
    &:focus {
      cursor: pointer;
    }
  }
}

// .layout-content {
// }

.nav-bottom {
  background-color: color.$light;
  padding: 16px 24.5px;
  font-size: 14px;
  margin-top: 20px;
}

.ant-menu-light .ant-menu-submenu-title:hover {
  color: color.$warning !important;
}

.menu-layout-header {
  background-color: color.$spa-color;
  border-bottom: 0px;
  color: color.$white;
  flex: 1;
  margin: auto 0;

  .menu-title {
    margin-left: 5px;
    &:hover {
      color: color.$warning;
    }
  }
}

.nav-btn-info {
  font-weight: 600;
  font-size: 14px;
  color: color.$dark-disable;
  margin-top: 5px;
  &:hover,
  &:focus {
    cursor: pointer;
    color: color.$spa-color;
  }
}

.div-sub-menu-icon {
  align-items: center;
  justify-content: center;
  display: flex;
}

.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin-top: 5px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:first-child {
  -webkit-border-radius: 10px 10px 0 0;
  -moz-border-radius: 10px 10px 0 0;
  border-radius: 10px 10px 0 0;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup.ant-menu-submenu > .ant-menu .ant-menu-item:last-child {
  -webkit-border-radius: 0 0 10px 10px;
  -moz-border-radius: 0 0 10px 10px;
  border-radius: 0 0 10px 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu,
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu-submenu > .ant-menu {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: color.$primary;
  color: #444342;
}
.gx-submenu-popup-curve.ant-menu-submenu-popup .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected > a {
  color: #444342;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected::after,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected::after {
  border-bottom: 0px;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu:hover,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-active,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-open,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  color: color.$warning;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  border-bottom: 0px;
}

.ant-menu-item .ant-menu-item-icon + span,
.ant-menu-submenu-title .ant-menu-item-icon + span,
.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  display: none;
}

.ant-menu-vertical.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-left.ant-menu-sub:not([class*='-active']),
.ant-menu-vertical-right.ant-menu-sub:not([class*='-active']) {
  border-radius: 10px;
  margin-top: 2px;
}
