$spa-color: #1e3150;

$primary: #00a3ff;
$primary-second: #007bff;
$primary-hover: #0069d9;
$primary-disable: #58aaff;

$secondary: #6c757d;
$secondary-hover: #5a6268;
$secondary-disable: #b0b6ba;

$success: #46a34a;
$success-hover: #218838;
$success-disable: #74c686;

$danger: #dc3545;
$danger-hover: #c82333;
$danger-disable: #e97b86;

$warning: #ffa011;
$warning-hover: #e08600;
$warning-disable: #ffc45e;

$info: #17a2b8;
$info-hover: #138496;
$info-disable: #67c3d0;

$light: #f8f9fa;
$light-hover: #e2e6ea;
$light-disable: #fafcfc;

$dark: #343a40;
$dark-hover: #23272b;
$dark-disable: #7a7e83;

$white: #ffffff;
$black: #000000;

.txt-color-success {
  color: $success;
}

.txt-color-dark {
  color: $dark;
}

.txt-color-primary {
  color: $primary;
}
