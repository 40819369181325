@use 'src/styles/font';
@use 'src/styles/color';

.base-btn {
  font-size: font.$font-normal;
  border-radius: 5px;
  height: 40px;
  color: color.$white;

  &:hover,
  &:focus {
    color: color.$white;
  }

  .btn-icon {
    margin-right: 6px;
    margin-top: -2px;
  }
}

.btn-h-33 {
  height: 33px;
}

.btn-h-30 {
  height: 30px;
}

.btn-w-30 {
  width: 30px;
}

.btn-success {
  background-color: color.$success;
  border-color: color.$success;
  &:hover,
  &:focus {
    background-color: color.$success-hover;
    border-color: color.$success-hover;
  }
}

.btn-danger {
  background-color: color.$danger;
  border-color: color.$danger;
  &:hover,
  &:focus {
    background-color: color.$danger-hover;
    border-color: color.$danger-hover;
  }
}

.btn-dark {
  background-color: color.$dark;
  border-color: color.$dark;
  &:hover,
  &:focus {
    background-color: color.$dark-hover;
    border-color: color.$dark-hover;
  }
}

.btn-info {
  background-color: color.$info;
  border-color: color.$info;
  &:hover,
  &:focus {
    background-color: color.$info-hover;
    border-color: color.$info-hover;
  }
}

.btn-warning {
  background-color: color.$warning;
  border-color: color.$warning;
  &:hover,
  &:focus {
    background-color: color.$warning-hover;
    border-color: color.$warning-hover;
  }
  &:disabled {
    background-color: color.$warning-disable;
    &:hover {
      background-color: color.$warning-disable;
    }
  }
}

.btn-primary {
  background-color: color.$primary;
  border-color: color.$primary;
  &:hover,
  &:focus {
    background-color: color.$primary-hover;
    border-color: color.$primary-hover;
  }
  &:disabled {
    background-color: color.$primary-disable;
    &:hover {
      background-color: color.$primary-disable;
    }
  }
}

.btn-primary-second {
  background-color: color.$primary-second;
  border-color: color.$primary-second;
  &:hover,
  &:focus {
    background-color: color.$primary-hover;
    border-color: color.$primary-hover;
  }
  &:disabled {
    background-color: color.$primary-disable;
    &:hover {
      background-color: color.$primary-disable;
    }
  }
}

.btn-secondary {
  background-color: color.$secondary;
  border-color: color.$secondary;
  &:hover,
  &:focus {
    background-color: color.$secondary-hover;
    border-color: color.$secondary-hover;
  }
}

.btn-info-border {
  background-color: color.$white;
  border-color: color.$info;
  color: color.$info;
  &:hover,
  &:focus {
    background-color: color.$info-hover;
    border-color: color.$info-hover;
    color: color.$white;
  }
}

.btn-primary-border {
  background-color: color.$white;
  border-color: color.$primary;
  color: color.$primary;
  &:hover,
  &:focus {
    background-color: color.$primary-hover;
    border-color: color.$primary-hover;
    color: color.$white;
  }
}

.btn-secondary-border {
  background-color: color.$white;
  border-color: color.$secondary;
  color: color.$secondary;
  &:hover,
  &:focus {
    background-color: color.$secondary-hover;
    border-color: color.$secondary-hover;
    color: color.$white;
  }
}

.btn-warning-border {
  background-color: color.$white;
  border-color: color.$warning;
  color: color.$warning;
  &:hover,
  &:focus {
    background-color: color.$warning;
    border-color: color.$warning;
    color: color.$white;
  }
}
